import React from "react";

import MachineVisionPage from "./MachineVisionPage"
import MachineVisionPage2 from "./MachineVisionPage2"; 
 import MachineVisionPage3 from "./MachineVisionPage3"; 
function Services() {
  return (
    <div>
      {/* <What /> */}
      {/* <Support /> */}
      <MachineVisionPage/>
      <MachineVisionPage2/>
      <MachineVisionPage3/>
      
       {/* <Machine4 /> */}
    </div>
  );
}

export default Services;
